<script setup>
import { useNuxtApp } from "#app";

const calculator = useCalculator();
const route = useRoute();
const topPage = ref();

const carsAvailable = computed(() => cars.value.data?.filter(car => car.price).sort((a, b) => a.modelName > b.modelName ? 1 : -1));
const carsUnavailable = computed(() => cars.value.data?.filter(car => !car.price).sort((a, b) => a.modelName > b.modelName ? 1 : -1));

const {data: cars} = await useAsyncData(() => new Promise(async resolve => {
  const models = await useBackend().getModels({brand: route.params.brand});
  await calculator.getInstallment(models);
  resolve(models)
}))

const brand = computed(() => route.params.brand?.toUpperCase())

useHead({
  title: `Leasing ${brand.value} - wybierz model i sfinansuj on-line!`,
  meta: [
    {name: 'description', content: `${brand.value} w leasingu dla przedsiębiorców i konsumentów ✔️ Decyzja leasingowa w 15 min ✔️ Szeroki wybór modeli i atrakcyjne rabaty ✔️ Sprawdź!`}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl'+route.path}
  ]
});

</script>

<template>
  <Container no-paddings>
    <AppTop />
    <div class="pb-10" id="top">
      <LayoutColumns>
        <LayoutMainCarsColumn ref="topPage">
          <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6">
            <MainContentHeader :counter="carsAvailable.length">Oferta samochodów {{ brand }} w leasingu</MainContentHeader>
          </div>
          <template v-for="car in carsAvailable">
            <CarTile :car="car" />
          </template>
          <template v-if="carsUnavailable.length">
            <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -my-6">
              <SubContentHeader :counter="carsUnavailable.length">Modele dostępne w ofercie indywidualnej</SubContentHeader>
            </div>
            <template v-for="car in carsUnavailable">
              <CarTile :car="car" unavailable />
            </template>
          </template>
        </LayoutMainCarsColumn>
        <LayoutSideColumn class="mt-20">
          <FormContact :car="carsAvailable[0] || carsUnavailable[0]" />
        </LayoutSideColumn>
      </LayoutColumns>
    </div>
  </Container>

  <Container>
    <SeoMoreAboutModels :cars="cars.data" :title="`Modele ${brand}:`" />
  </Container>

  <Container with-border-top>
    <SeoBrand />
  </Container>

  <Container with-border-top>
    <BlockHowTo />
  </Container>

  <Container gray>
    <BlockFeaturesComparedText />
  </Container>

  <Container>
    <FormContact title="W każdym momencie możesz skorzystać z&nbsp;pomocy naszych Doradców" standalone />
  </Container>

  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
